<template lang="html">
  <button
    type="button" :disabled="disabled"
    class="btn btn-default"
    :title="title"
    :class="classes" @click.stop.prevent="$emit('click')"
  >
    <span class="button-content-container">
      <slot name="icon">
        <i v-if="autoIcon && typeof autoIcon === 'string'" class="fa" :class="[`fa-${autoIcon}`]" />
        <FontAwesomeIcon v-else-if="autoIcon" :icon="autoIcon" />
      </slot>
      <span class="button-content">
        <slot>
          <IxRes v-if="!$slots.default && defaultText">Common.SR_Common.{{ defaultText }}</IxRes>
        </slot>
      </span>
    </span>
  </button>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: {}, // font-awesome icon without the `fa-` prefix, for example: `<IxButton icon="star" />`,
    colored: Boolean,
    large: Boolean,
    save: Boolean,
    submit: Boolean,
    cancel: Boolean,
    add: Boolean,
    edit: Boolean,
    remove: Boolean,
    restore: Boolean,
    upload: Boolean,
    download: Boolean,
    back: Boolean,
    next: Boolean,
    details: Boolean,
    ban: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    block: Boolean,
    title: String,
    refresh: Boolean
  },
  computed: {
    classes () {
      return {
        'btn-primary': this.colored,
        'btn-success': this.save || this.submit || this.upload,
        'btn-danger': this.cancel || this.remove,
        'btn-xs': !this.large,
        'large': this.large,
        'rounded': this.rounded,
        'btn-block': this.block
      }
    },
    autoIcon () {
      if (this.icon) return this.icon

      if (this.save) {
        return 'floppy-o'
      } else if (this.submit) {
        return 'check'
      } else if (this.cancel || this.remove) {
        return 'trash'
      } else if (this.restore) {
        return 'archive'
      } else if (this.add) {
        return 'plus'
      } else if (this.edit) {
        return 'edit'
      } else if (this.back) {
        return 'arrow-circle-o-left'
      } else if (this.next || this.details) {
        return 'arrow-circle-o-right'
      } else if (this.upload) {
        return 'cloud-upload'
      } else if (this.download) {
        return 'download'
      } else if (this.ban) {
        return 'ban'
      } else if (this.refresh) {
        return 'refresh'
      } else {
        return ''
      }
    },
    defaultText () {
      if (this.save) {
        return 'Save'
      } else if (this.submit) {
        return 'Submit'
      } else if (this.cancel) {
        return 'Cancel'
      } else if (this.remove) {
        return 'Remove'
      } else if (this.restore) {
        return 'Restore'
      } else if (this.add) {
        return 'Add'
      } else if (this.edit) {
        return 'Edit2'
      } else if (this.back) {
        return 'Back'
      } else if (this.next) {
        return 'Next'
      } else if (this.details) {
        return 'ProceedToDetails'
      } else if (this.refresh) {
        return 'Refresh'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:not(.btn-xs) > .fa {
  font-size: 1.3em;
}
.fa + .button-content:not(:empty) {
  margin-left: 2px;
  margin-right: 2px;
}
:not(.btn-xs) > :first-child + .button-content:not(:empty) {
  margin-left: 5px;
  margin-right: 5px;
}
button {
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;

  .button-content-container {
    display: flex;
    align-items: center;

    .button-content {
      flex: 1;
    }
  }
}
.rounded {
  border-radius: 6px;
}
</style>
