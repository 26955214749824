
/// returns an array of children of the given 'start' node that fulfill the given 'condition',
/// children not meeting the condition will have their children checked and so on
export const findChildren = (start, condition) => {
  let newCandidates = start.$children
  const results = []

  while (newCandidates.length > 0) {
    const currentCandidates = newCandidates
    newCandidates = []

    for (const candidate of currentCandidates) {
      if (condition(candidate)) {
        results.push(candidate)
      } else {
        newCandidates = newCandidates.concat(candidate.$children)
      }
    }
  }

  return results
}

/// returns the first parent in the hierarchy chain going upwards from 'start' that fulfills the given 'condition'
export const findParent = (start, condition) => {
  let parent = start.$parent

  while (parent) {
    if (condition(parent)) {
      return parent
    }

    parent = parent.$parent
  }
}

export default {
  findParent,
  findChildren
}
