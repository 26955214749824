const iconLookup = {
  error: 'remove',
  warning: 'warning-sign',
  success: 'ok'
}

export default {
  props: {
    label: String,
    required: Boolean
  },
  computed: {
    icon () {
      return iconLookup[this.state]
    },
    srState () {
      return this.state ? `(${this.state})` : null
    },
    feedbackClasses () {
      const classes = this.state ? ['has-feedback'] : []

      if (this.state) {
        classes.push(`has-${this.state}`)
      }
      return classes
    }
  }
}
