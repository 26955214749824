<template lang="html">
  <div
    ref="modal" class="modal fade"
    :class="inline ? 'keep-inline' : ''"
    tabindex="-1" role="dialog"
  >
    <div :class="{'modal-lg': size === 'large', 'modal-xlarge': size === 'xlarge', 'modal-dialog': true}" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="close-controls">
            <a
              v-if="$slots.close"
              class="close-text"
              href="#" data-dismiss="modal"
            >
              <slot name="close" />
            </a>
            <button
              v-if="!blocking || !$slots.footer || $slots.close" type="button"
              class="close"
              data-dismiss="modal" aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <h4 class="modal-title">
            <slot name="title">
              {{ title }}
            </slot>
          </h4>
          <span v-if="description || $slots.description">
            <slot name="description">{{ description }}</slot>
          </span>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
      <FrsLoadingIndicator
        :loading="loading"
        :saving="saving"
        :error="error"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator
  },
  props: {
    title: String,
    value: Boolean,
    size: String,
    blocking: {
      type: Boolean,
      default: true
    },
    description: String,
    loading: Boolean,
    saving: Boolean,
    error: Boolean,
    requests: Array,
    inline: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value (value) {
      $(this.$refs.modal).modal(value ? 'show' : 'hide')
    }
  },
  // adapter between bootstrap-supplied ways to hide a modal and reactive binding
  mounted () {
    const $modal = $(this.$refs.modal)
    $modal.modal({
      backdrop: this.blocking ? 'static' : true,
      keyboard: this.blocking,
      show: false
    })
    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('input', false)
      this.$emit('hide')
    })
    $(this.$refs.modal).on('shown.bs.modal', () => this.$emit('shown'))
    $(this.$refs.modal).on('hidden.bs.modal', () => this.$emit('hidden'))
    if (this.value) {
      $(this.$refs.modal).modal('show')
      this.$emit('show')
    }
  },
  beforeDestroy () {
    $(this.$refs.modal).off('hide.bs.modal')
    $(this.$refs.modal).off('shown.bs.modal')
    $(this.$refs.modal).off('hidden.bs.modal')
    $(this.$refs.modal).modal('hide').data('bs.modal', null)
  }
}
</script>

<style lang="scss" scoped>
.modal-xlarge {
  width: 90vw !important;
}

.close-controls {
  float: right;
  display: flex;
  align-items: center;

  .close-text {
    margin-right: 0.5em;
  }
}
</style>
