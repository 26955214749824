<template lang="html">
  <LabelAndMessage
    :id="id" :label="label"
    :required="required"
    :feedback-classes="feedbackClasses" :description="combinedDescription"
    :message="message"
  >
    <div :class="{'input-group': addon || addonLeft}" class="input-field">
      <span v-if="addonLeft" class="input-group-addon">{{ addonLeft }}</span>
      <div style="position: relative">
        <textarea
          v-if="multiline"
          :id="id" ref="input"
          class="form-control" :class="{'no-resize': noResize}"
          :rows="rows ? rows : 3"
          :aria-describedby="`${id}-sr-status`"
          :value="displayValue" :placeholder="actualPlaceholder"
          :readonly="disabled" :disabled="disabled"
          @input="onInput"
          @blur="onBlur"
        />
        <input
          v-else
          :id="id" ref="input"
          :type="inputType" :list="listId"
          class="form-control" :aria-describedby="`${id}-sr-status`"
          :value="displayValue" :placeholder="actualPlaceholder"
          :readonly="disabled" :disabled="disabled"
          @input="onInput" @keyup.enter="onEnter"
          @blur="onBlur"
        >
        <transition name="icon" mode="out-in">
          <span
            v-if="state" :key="icon"
            class="glyphicon form-control-feedback"
            :class="[icon ? `glyphicon-${icon}` : null]" aria-hidden="true"
          />
        </transition>
      </div>
      <span v-if="state" :id="`${id}-status-sr`" class="sr-only">{{ srState }}</span>
      <span v-if="addon" class="input-group-addon">{{ addon }}</span>
    </div>
    <slot slot="label" name="label" />
    <slot v-if="$slots.description" slot="description" name="description" />
  </LabelAndMessage>
</template>

<script>
import {debounce} from 'lodash'

import InputMixin from './input-base/InputMixin'
import FormPartMixin from 'src/vue/components/forms/FormPartMixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'
import RuleMixin from './RuleMixin'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import LabelAndMessage from './input-base/LabelAndMessage'

const resources = {
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}
let counter = 0

export default {
  components: {
    LabelAndMessage
  },
  mixins: [
    RuleMixin,
    InputMixin,
    makeResourceMixin(resources),
    FormPartMixin,
    ChangeDetectionMixin
  ],
  props: {
    value: String,
    placeholder: String, // placeholder has higher priority than default property of messages object
    addon: {},
    addonLeft: {},
    multiline: Boolean,
    rows: Number,
    listId: String,
    description: String,
    disabled: Boolean,
    name: String,
    label: String,
    resetValue: String,
    type: String,
    noResize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: `text-input-${counter++}`,
      dirty: false,
      inputText: '',

      onInput: debounce(this.onInputDebounced, 300)
    }
  },
  computed: {
    overrideInputText () {
      return this.modifiedValue !== this.value
    },
    displayValue () {
      return this.overrideInputText
        ? this.value
        : this.inputText
    },
    inputType () {
      if (this.type) {
        return this.type
      } else {
        return 'text'
      }
    },
    actualPlaceholder () {
      return this.placeholder || this.ruleDescription
    },
    combinedDescription () {
      return [this.ruleDescription, this.description].filter(x => x)
    },
    message () {
      const builtinMessages = {
        error: this.SR_InfoMessages.RequiredField
      }
      const combinedMessages = Object.assign(builtinMessages, this.ruleMessages)

      return combinedMessages[this.state]
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (this.value && this.value !== '') {
          states.required = 'success'
        } else {
          states.required = 'error'
        }
      }
      states.rule = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    },
    modifiedValue () {
      return this.inputText.trim().replace(/\t/g, ' ').replace(/ +/g, ' ')
    }
  },
  methods: {
    onInputDebounced (event) {
      this.dirty = true
      this.inputText = event.target.value
      this.$emit('input', this.modifiedValue)
    },
    onEnter () {
      this.dirty = true
      this.$emit('enter', event.target.value)
      this.$emit('submit')
    },
    onBlur (event) {
      this.inputText = event.target.value
      this.$emit('input', this.modifiedValue)
      this.$emit('blur', this.modifiedValue)
      this.inputText = this.modifiedValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~./input-base/input.scss';
</style>
