<template lang="html">
  <div :class="feedbackClasses" style="position: relative">
    <label v-if="label || $slots.label" class="control-label" :for="id">
      <slot name="label">{{ label ? label.toString() : '' }}</slot>
      <span v-if="required"> *</span>
    </label>
    <VPopover
      v-if="$slots.description || (description && description.length)"
      trigger="hover"
    >
      <span class="description-button">
        <a href="#" @click.prevent>
          <i class="fa fa-question-circle" />
        </a>
      </span>
      <template #popover>
        <slot name="description">
          <div class="default-description">
            <span v-for="(line, i) in description" :key="i">
              {{ line }}
            </span>
          </div>
        </slot>
      </template>
    </VPopover>

    <slot />
    <transition name="message">
      <span v-if="message || $slots.message" :id="`${id}-status-message`" class="help-block">
        <slot name="message">{{ message }}</slot>
      </span>
    </transition>
  </div>
</template>
<script>
import {VPopover} from 'v-tooltip'

export default {
  components: {
    VPopover
  },
  props: {
    id: String,
    label: {},
    required: Boolean,
    feedbackClasses: [Array, Object],
    description: {
      type: Array,
      default: () => []
    },
    message: String
  }
}
</script>

<style lang="scss" scoped>
label {
  transition: color 0.25s;
}

.message-enter-active, .message-leave-active {
  transition: opacity 0.25s, max-height 0.25s;
}

.message-enter, .message-leave-to {
  opacity: 0;
  max-height: 0;
}

.message-enter-to, .message-leave {
  opacity: 1;
  max-height: 3rem;
}

.v-popover {
  display: inline;
}

.description-button {
  margin-left: 0.25em;
}

.default-description {
  display: flex;
  flex-direction: column;
}
</style>
