const supportedStates = ['warning', 'error', 'success', 'info']

const boolStateLookup = {
  true: 'success',
  null: 'warning',
  false: 'error'
}

// supported rule function return values
//
// nullable boolean
// const f1 = () => null || true || false || undefined
// state directly
// const f2 = () => 'warning' || 'success' || 'error' || undefined
// state object with message
// const f3 = () => ({state: 'warning' || 'success' || 'error', message: ''})
// implicit error message
// const f4 = () => 'not enough minerals'

const isMessageResult = result => {
  return typeof result === 'string' && !supportedStates.includes(result)
}

const ruleResultToMessage = result => {
  return isMessageResult(result) ? result : result.message
}

const ruleResultToState = result => {
  if (result.state !== undefined) {
    result = result.state
  }
  if (result === undefined) {
    return undefined
  }
  return boolStateLookup[result] || (supportedStates.includes(result) ? result : 'error')
}

const isEmpty = value => {
  return value === null || value === ''// || (value instanceof Array && !value.length) // ?
}

export default {
  props: {
    value: {},
    rule: Function,
    messages: Object
  },
  computed: {
    ruleResult () {
      return this.rule && !isEmpty(this.value) ? this.rule(this.value) : undefined
    },
    ruleState () {
      return this.ruleResult !== undefined ? ruleResultToState(this.ruleResult) : undefined
    },
    ruleMessages () {
      const messages = {}
      if (this.ruleResult !== undefined) {
        if (this.rule.messages) {
          Object.assign(messages, this.rule.messages)
        }
        if (this.messages) {
          Object.assign(messages, this.messages)
        }
        const implicitMessage = ruleResultToMessage(this.ruleResult)
        if (implicitMessage !== undefined) {
          messages[this.ruleState] = implicitMessage
        }
      }
      return messages
    },
    ruleMessage () {
      return this.ruleMessages[this.ruleState]
    },
    ruleDescription () {
      if (this.messages && this.messages.description) {
        return this.messages.description
      }
      if (this.rule && this.rule.messages && this.rule.messages.description) {
        return this.rule.messages.description
      }
    }
  }
}
