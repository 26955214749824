import {isEqual, isEqualWith, sortBy} from 'lodash'

import {findParent, findChildren} from './hierarchy-traversal'

export default {
  data () {
    return {
      initialValue: undefined,
      fieldsChanged: {},
      fieldsDirty: {},
      dirty: false,
      suppressChangeDetection: false
    }
  },
  computed: {
    actualResetValue () {
      return this.resetValue !== undefined ? this.resetValue : this.initialValue
    },
    inferredChanges () {
      return Object.values(this.fieldsChanged).some(x => x)
    },
    hasChanges () {
      const ownChanges = !isEqualWith(this.value, this.actualResetValue, (a, b) => {
        if (Array.isArray(a) && Array.isArray(b)) {
          return isEqual(sortBy(a), sortBy(b))
        }
      })

      return this.$_ChangeDetectionMixin_HasValue
        ? ownChanges
        : this.inferredChanges
    }
  },
  methods: {
    reset (silent) {
      if (!silent && this.$_ChangeDetectionMixin_HasValue) {
        this.$emit('input', this.actualResetValue)
      }

      this.dirty = false
      // propagate downwards
      const children = findChildren(this, child => child.$options && child.$options.$_ChangeDetectionMixin_ImplementsChangeDetectionMixin)
      children.forEach(x => x.reset(silent))
    },
    updateFieldChanged (fieldName, value) {
      this.$set(this.fieldsChanged, fieldName, value)
    },
    updateFieldDirty (fieldName, value) {
      this.$set(this.fieldsDirty, fieldName, value)
      if (this.value === undefined) {
        this.dirty = Object.values(this.fieldsDirty).some(x => x)
      }
    },
    removeChild (fieldName) {
      this.$delete(this.fieldsChanged, fieldName)
      this.$delete(this.fieldsDirty, fieldName)
    }
  },
  watch: {
    dirty (value) {
      if (!this.$_ChangeDetectionMixin_ShouldPropagate) return

      const parent = findParent(this, parent => parent.$options && parent.$options.$_ChangeDetectionMixin_ImplementsChangeDetectionMixin)
      if (parent) {
        if (this.name) {
          parent.updateFieldDirty(this.name, value)
        }
      }
    },
    // immediate?
    hasChanges (hasChanges) {
      if (!this.$_ChangeDetectionMixin_ShouldPropagate) return

      const parent = findParent(this, parent => parent.$options && parent.$options.$_ChangeDetectionMixin_ImplementsChangeDetectionMixin)
      if (parent) {
        if (this.name) {
          parent.updateFieldChanged(this.name, hasChanges)
        }
      }
    }
  },
  created () {
    if (this.$options.props && this.$options.props.name && this.name !== undefined) {
      this.$_ChangeDetectionMixin_ShouldPropagate = true
    } else if (findParent(this, parent => parent.$options && parent.$options.$_ChangeDetectionMixin_ImplementsChangeDetectionMixin)) {
      throw new Error(`ChangeDetectionMixin used in component without a 'name' prop but within a parent listening for its changes. Propagation of validation state will not work in this case.\nDefined props:\n${JSON.stringify(this.$options.props, null, 2)}`)
    }

    if (this.$options.props && this.$options.props.value !== undefined) {
      this.$_ChangeDetectionMixin_HasValue = true

      this.initialValue = this.value
      this.$on('input', () => {
        if (!this.suppressChangeDetection) {
          this.dirty = true
        }
      })

      if (this.$options.props.resetValue !== undefined) {
        this.$watch('resetValue', this.reset)
      }
    }
  },
  beforeDestroy () {
    if (!this.$_ChangeDetectionMixin_ShouldPropagate) return

    const parent = findParent(this, parent => parent.$options && parent.$options.$_ChangeDetectionMixin_ImplementsChangeDetectionMixin)
    if (parent) {
      if (this.name) {
        parent.removeChild(this.name)
      }
    }
  },
  $_ChangeDetectionMixin_ImplementsChangeDetectionMixin: true
}
