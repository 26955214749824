<template lang="html">
  <div class="frs-loading-overlay" :class="{'frs-loading-overlay-visible': show, error: showError, small, large}">
    <div v-if="showError" class="status-indicator">
      <FaIcon :icon="icons.error" size="3x" />
      <div v-if="!small" class="error-text">
        <IxRes>Common.SR_Common.Error</IxRes>
        <slot v-if="$slots.error" name="error" />
      </div>
    </div>
    <div v-else class="status-indicator">
      <FaIcon :icon="icons.loading" size="3x" spin />
      <span v-if="!small" class="loading-text">
        <IxRes v-if="saving">Common.SR_Common.IsSaving</IxRes>
        <IxRes v-else>Common.SR_Common.IsLoading</IxRes>
      </span>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {faSync} from '@fortawesome/free-solid-svg-icons'
import {faDizzy, faSadTear, faTired} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon as FaIcon} from '@fortawesome/vue-fontawesome'

import {int} from '@helpers/random'

export default {
  components: {
    FaIcon
  },
  props: {
    loading: Boolean,
    error: Boolean,
    requests: Array,
    small: Boolean,
    large: Boolean,
    saving: Boolean
  },
  data () {
    return {
      errorIconIndex: 0,
      errorIcons: [faDizzy, faSadTear, faTired]
    }
  },
  computed: {
    ...mapState('dataLoading', [
      'runningCounts',
      'erroredRequests'
    ]),
    requestState () {
      if (!this.requests) return {loading: false, error: false}

      return {
        loading: this.requests.some(id => this.runningCounts[id]),
        error: this.requests.some(id => this.erroredRequests[id])
      }
    },
    show () {
      return this.isLoading || this.hasErrored
    },
    isLoading () {
      return this.loading || this.requestState.loading
    },
    hasErrored () {
      return this.error || this.requestState.error
    },
    showError () {
      return this.hasErrored && !this.isLoading
    },
    icons () {
      return {
        loading: faSync,
        error: this.errorIcons[this.errorIconIndex]
      }
    },
    size () {
      if (this.large) return '3x'
      if (this.small) return '1x'
      return '3x'
    }
  },
  methods: {
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    invalidateAll () {
      if (this.requests) {
        this.requests.forEach(this.invalidate)
      }
    }
  },
  watch: {
    showError () {
      this.errorIconIndex = int(0, this.errorIcons.length - 1)
    },
    show: {
      immediate: true,
      handler (value) {
        this.$emit(value ? 'show' : 'hide')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.frs-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;

  background-color: #dedede;
  color: #aaa;
  box-shadow: rgba(0,0,0,0.02) inset 0px 0px 15px 5px;

  &.error {
    box-shadow: rgba(70,0,0,0.03) inset 0px 0px 15px 5px;
    background-color: #dec8c8;
    color: #a60000;
  }

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;

  &.frs-loading-overlay-visible {
    opacity: 0.95;
    pointer-events: all;
    user-select: none;
  }

  .status-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-text {
      margin-top: 0.5em;
      color: #777;
      animation: pulse 2s ease infinite running;
    }

    .error-text {
      margin-top: 0.5em;
      color: #710000;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &.small {
    font-size: 0.75rem;
  }

  &.large {
    font-size: 3rem;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
