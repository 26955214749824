import Vue from 'vue'

import store from '@store'
import {register} from 'src/js/infrastructure/setup-registry'

export const mount = (el, component, {router} = {}) => {
  return new Vue({
    store,
    el,
    router,
    render (h) {
      return h(component)
    }
  })
}

export function registerComponent (component, name, {el = '#mount', router} = {}) {
  const setup = ($ctx) => mount($ctx.find(el).get(0), component, {router})
  register(name, setup)
}
