import moment from 'moment'

export function int (min, maxInclusive) {
  if (maxInclusive === undefined) {
    return Math.round(Math.random() * min)
  } else {
    return min + Math.round(Math.random() * (maxInclusive - min))
  }
}

export function float (min, maxInclusive) {
  if (maxInclusive === undefined) {
    return Math.random() * min
  } else {
    return min + Math.random() * (maxInclusive - min)
  }
}

export function range (min, max, {step = 1, offset = 0} = {}) {
  return new Array(int(min, max)).fill(null).map((x, i) => offset + i * step)
}

export function date (day, month, year) {
  day = day < 10 ? `0${day}` : day
  month = month < 10 ? `0${month}` : month
  return `${day}.${month}.${year}`
}

export function text () {
  let text = ''
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export const fromArray = array => array[int(0, array.length - 1)]
export const sample = (array, count) => new Array(count).fill(null).map(() => fromArray(array))
export const harvestYear = () => {
  const randomYear = moment().year() + int(-1, 1)
  return moment().year(randomYear)
}
