import {findParent, findChildren} from './hierarchy-traversal'

const priority = ['error', 'warning', 'info', 'success', undefined]

const combineStates = states => {
  for (const state of priority) {
    if (states.indexOf(state) > -1) {
      return state
    }
  }
}

export default {
  data () {
    return {
      fieldStates: {},
      dirty: false
    }
  },
  computed: {
    inferredState () {
      return combineStates(Object.values(this.fieldStates))
    },
    state () {
      return this.inferredState
    }
  },
  methods: {
    validate () {
      this.dirty = true

      const children = findChildren(this, child => child.$options && child.$options.$_FormPartMixin_IsFormPart)
      children.forEach(child => child.validate())
    },
    async doIfValid (f) {
      this.validate()

      await this.$nextTick()

      if (this.state === 'success') {
        return f()
      }
    },
    combineStates (stateObject) {
      return combineStates(Object.values(stateObject))
    },
    $_FormPartMixin_propagateState (state) {
      if (!this.$_FormPartMixin_ShouldPropagate) return

      const parent = findParent(this, parent => parent.$options && parent.$options.$_FormPartMixin_IsFormPart)

      if (parent) {
        if (!this.name) {
          throw new Error(`invalid FormPart name '${this.name}', component is part of a FormPart parent and has a name prop, so the prop has to be non-empty`)
        }
        parent.$emit('$_FormPartMixin_ChildChanged', {fieldName: this.name, state: state})
      }
    }
  },
  watch: {
    state (value) {
      this.$_FormPartMixin_propagateState(value)
    }
  },
  created () {
    if (this.$options.props && this.$options.props.name) {
      this.$_FormPartMixin_ShouldPropagate = true
    } else if (findParent(this, parent => parent.$options && parent.$options.$_FormPartMixin_IsFormPart)) {
      throw new Error(`FormPartMixin used in component without a 'name' prop but within a parent listening for its changes. Propagation of validation state will not work in this case.\nDefined props:\n${JSON.stringify(this.$options.props, null, 2)}`)
    }

    this.$on('$_FormPartMixin_ChildChanged',
      event => {
        this.$set(this.fieldStates, event.fieldName, event.state)
      })
  },
  beforeDestroy () {
    this.$_FormPartMixin_propagateState(undefined)
  },
  $_FormPartMixin_IsFormPart: true
}
