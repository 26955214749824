import Vue from 'vue'

import store from '@store'

let missingTranslations = {}

const signalMissingTranslation = (resource, key) => {
  // first missing translation request in this frame, schedule server fetch
  if (!Object.keys(missingTranslations).length) {
    Vue.nextTick(() => {
      const requiredTranslations = missingTranslations
      missingTranslations = {}
      store.dispatch('i18n/requestTranslations', requiredTranslations)
    })
  }

  const locale = store.state.i18n.locale

  if (!missingTranslations[locale]) {
    missingTranslations[locale] = {}
  }
  if (!missingTranslations[locale][resource]) {
    missingTranslations[locale][resource] = new Set()
  }
  missingTranslations[locale][resource].add(key)
}

const isVueInternal = (key) => typeof key !== 'string' || key === 'toJSON' || key === '_isVue' || key === '_vm' || key === 'render' || key === 'state'

const createProxy = (alias, resource) => new Proxy(store.state.i18n, {
  ownKeys (state) {
    return Object.keys(state.translations[resource] || {})
  },
  get (state, key) {
    if (key === '_alias') {
      return alias
    }
    if (key === '_resource') {
      return resource
    }
    const existing = state.translations[resource] ? state.translations[resource][key] : undefined
    if (existing === undefined && !isVueInternal(key)) {
      signalMissingTranslation(resource, key)
    }
    return existing
  }
})

export default (resources) => {
  if (!window.Proxy) {
    console.error('ES6 Proxy not supported, localization mixin will not work correctly')
  }
  const computed = {}
  for (const alias in resources) {
    computed[alias] = function () {
      return window.Proxy ? createProxy(alias, resources[alias]) : {}
    }
  }
  return {computed}
}
