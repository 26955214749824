import {get, set, cloneDeep, toPath, last} from 'lodash'

export const mapFormFieldsFromValue = propertyNames => {
  return propertyNames.reduce((computeds, propertyPath) => {
    const computedName = last(toPath(propertyPath))

    computeds[computedName] = {
      get () {
        return get(this.value, propertyPath)
      },
      set (value) {
        const clone = cloneDeep(this.value)

        set(clone, propertyPath, value)

        this.$emit('input', clone)
      }
    }

    return computeds
  }, {})
}

export const extractFormModelsFromProperties = mapping => {
  return Object.entries(mapping).reduce((computeds, [modelPropertyName, sourcePropertyNames]) => {
    computeds[modelPropertyName] = {
      get () {
        return sourcePropertyNames.reduce((model, propertyName) => {
          model[propertyName] = this[propertyName]

          return model
        }, {})
      },
      set (value) {
        for (const propertyName of sourcePropertyNames) {
          this[propertyName] = value[propertyName]
        }
      }
    }

    return computeds
  }, {})
}
